import React, { createContext, useState, useEffect } from "react";
import clientV3 from "./apolloClient";
import {
  COUPON_APPLY,
  REMOVE_ARRANGER_COUPON,
} from "./queries/insiderDashboard/loyaltyProgramQuery";
import { SOURCE_TYPE_PROMO } from "./config/constants";

export const LoyaltyProgramContext = createContext();

export const LoyaltyProgramProvider = ({ children }) => {
  const [loyaltyProgramStates, setLoyaltyProgramStates] = useState({
    codeLoading: false,
    couponCode: "",
    couponName: "",
    couponDisAmount: 0,
    couponCurrency: "",
    bookingTotalPrice: 0,
    bookingBalance: 0,
    couponApplyError: "",
  });

  const handleCouponApply = (
    couponCode,
    arrangerCouponId,
    couponName,
    reservationId,
    productTypeId,
    getReservationConfirmation,
  ) => {
    clientV3
      .mutate({
        mutation: COUPON_APPLY,
        variables: {
          input: {
            arranger_coupon_id: arrangerCouponId,
            reservation_id: reservationId,
            product_type_id: productTypeId ? productTypeId : null,
          },
        },
      })
      .then((res) => {
        let resData = res?.data?.couponApply;
        if (resData.sources && resData.sources.length > 0) {
          let getprice = resData.sources.find(
            (val) => val.source_type_id === 13,
          );
          setLoyaltyProgramStates({
            ...loyaltyProgramStates,
            codeLoading: false,
            couponDisAmount: getprice
              ? getprice?.customer_charge?.physical_amount
              : 0,
            couponCurrency: getprice
              ? getprice?.customer_charge?.physical_currency
              : "",
            couponCode: couponCode,
            couponName: couponName,
            bookingTotalPrice: resData.total.physical_amount,
            bookingBalance: resData.total.physical_amount,
          });
          getReservationConfirmation &&
            getReservationConfirmation(reservationId);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoyaltyProgramStates({
          ...loyaltyProgramStates,
          codeLoading: false,
          couponApplyError: err.message.replace(/GraphQL error:/g, ""),
        });
      });
  };

  const handleRemoveCoupon = (arrangerCouponId, reservationId) => {
    clientV3
      .mutate({
        mutation: REMOVE_ARRANGER_COUPON,
        variables: {
          input: {
            arranger_coupon_id: arrangerCouponId,
            reservation_id: String(reservationId),
          },
        },
      })
      .then((res) => {
        let resData = res.data.removeCoupon;
        if (resData) {
          setLoyaltyProgramStates({
            ...loyaltyProgramStates,
            codeLoading: false,
            couponCode: "",
            couponDisAmount: 0,
            couponCurrency: "",
            bookingTotalPrice: resData.total.physical_amount,
            bookingBalance: resData.total.physical_amount,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setLoyaltyProgramStates({
          ...loyaltyProgramStates,
          codeLoading: false,
          couponApplyError: err.message.replace(/GraphQL error:/g, ""),
        });
      });
  };

  useEffect(() => {
    loyaltyProgramStates?.couponApplyError !== "" &&
      setTimeout(() => {
        setLoyaltyProgramStates({
          ...loyaltyProgramStates,
          couponApplyError: "",
        });
      }, 3000);
  }, [loyaltyProgramStates?.couponApplyError]);

  return (
    <LoyaltyProgramContext.Provider
      value={{
        loyaltyProgramStates,
        setLoyaltyProgramStates,
        handleCouponApply,
        handleRemoveCoupon,
      }}
    >
      {children}
    </LoyaltyProgramContext.Provider>
  );
};
